.settings-page {
  text-align: center;
}

.image-name {
  display: block;
  margin:2px;
}

.image-modal {
  video {
    max-width: 300px;
    height:auto;
  }
  .nft-container {
    height: auto;
  }

  img {
    max-width: 300px;
    height: auto;
  }
}

.urlInput {
  min-width: 50vw;
  width: 50vw;
}

.moreSettingsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.advancedSettingsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
