.fullscreen-button {
  position: absolute;
  //top: -20vh;
  //left: 30vw;
}

.info-box--fixed {
  position: relative;
  text-align: center;
}

.info-box--floating {
  position: absolute;
  bottom: 0;
  //overflow: hidden;
  z-index: 999;
  padding: 15px;
  background: white;
  border-radius: 7px;

  &__hide {
    -moz-animation: cssAnimationHide 0s ease-in 0s forwards; /* Firefox */
    -webkit-animation: cssAnimationHide 0s ease-in 0s forwards; /* Safari and Chrome */
    -o-animation: cssAnimationHide 0s ease-in 0s forwards; /* Opera */
    animation: cssAnimationHide 0s ease-in 0s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  &:hover {
    cursor: pointer;

    * {
      -moz-animation: cssAnimationShow 0s ease-in 5s forwards; /* Firefox */
      -webkit-animation: cssAnimationShow 0s ease-in 5s forwards; /* Safari and Chrome */
      -o-animation: cssAnimationShow 0s ease-in 5s forwards; /* Opera */
      animation: cssAnimationShow 0s ease-in 5s forwards;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes cssAnimationHide {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}
@-webkit-keyframes cssAnimationHide {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes cssAnimationShow {
  0%   {opacity: 0;}
  90%  {opacity: 0;}
  100% {opacity: 1;}
}
@-webkit-keyframes cssAnimationShow {
  0%   {opacity: 0;}
  90%  {opacity: 0;}
  100% {opacity: 1;}
}
