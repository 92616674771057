.nft-container {
  height: 100vh;
  overflow: hidden;
}

.nft {
  object-fit: fill;
  //    background-position: center center;
  //    background-size: cover;
  &--0 {
    width: 100vw;
    height: 100vh;
  }
  &--90 {
    width: 100vh; /*vh because rotated*/
    height: 100vw;
    transform: rotate(90deg) translateY(-100%);
    transform-origin:top left;
  }
  &--180 {
    width: 100vw;
    height: 100vh;
    transform: rotate(180deg) translateX(-100%) translateY(-100%);
    transform-origin:top left;
  }
  &--270 {
    width: 100vh; /*vh because rotated*/
    height: 100vw;
    transform: rotate(270deg) translateX(-100%);
    transform-origin:top left;
  }
}
